import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 18" />
    <div>
      <h1>Luke 18</h1>
      <p>Endelig fredag!</p>
      <p>Gleder meg masse til å være med dere begge i helga. Kan vi pynte juletre?</p>
      <p>Jeg stiller parat med ny smekke, som trengs nå som jeg får mer sånn derre fastere melk fra skjeen du putter i munnen min.</p>
      <p>Husk å synge fredagssangen i dag!</p>
      <p>Love you all I can!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke